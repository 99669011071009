// extracted by mini-css-extract-plugin
export var header = "contact-module--header--3LwQG";
export var outContact = "contact-module--outContact--2Xlly";
export var contactform = "contact-module--contactform--2soyb";
export var input = "contact-module--input--3ezXZ";
export var inputButton = "contact-module--inputButton--13LG7";
export var hidden = "contact-module--hidden--3PfZA";
export var infoTitle = "contact-module--infoTitle--1C7jz";
export var infoDescription = "contact-module--infoDescription--2myYR";
export var infoContactTitle = "contact-module--infoContactTitle--3YuoJ";
export var infoContact = "contact-module--infoContact--2zOOR";
export var contactTitle = "contact-module--contactTitle--Hja0M";
export var formButton = "contact-module--formButton--87_Qf";
export var contactDetail = "contact-module--contactDetail--2MJCR";
export var contactContainer = "contact-module--contactContainer--25Eou";
export var formContainer = "contact-module--formContainer--2d65V";